<template>
  <v-app id="root">
    <v-app-bar app dense dark color="primary" class="app-header">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <!-- <v-row no-gutters>
          <v-col align-self="center"><v-img class="ma-0 pa-0" :src="icon" width="30" height="30"/></v-col>
          <v-col align-self="center" class="title ml-2">{{ name }} [Admin]</v-col>           
        </v-row> -->
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text class="text-normal">
        {{ username }}
        <v-icon right>mdi-account</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer dark app color="secondary" v-model="drawer" class="app-sidemenu" temporary width="270">
      <v-list-item>
        <v-list-item-content class="text-center">
          <v-list-item-title class="title">
            <v-container>
              <v-img :src="logo" />
            </v-container>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <template v-for="item in items">
          <v-list-item :key="item.title" :to="item.path" link color="white">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <v-row align="center" justify="space-around" class="mb-2">
          <v-chip color="secondary" small> v{{ version }} </v-chip>
          <v-btn text small elevation="0" color="white" class="text-normal" :loading="loading" @click.native="logout()">
            Salir
            <v-icon right dark>mdi-logout</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
      <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
        {{ msgSuccess }}
      </v-snackbar>
      <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
        {{ msgError }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'root',
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    version: process.env.VUE_APP_VERSION,
    name: process.env.VUE_APP_NAME,
    logo: require('@/assets/logo_menu.png'),
    icon: require('@/assets/icon.png'),
    username: '',
    path: '/api/logout',
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    drawer: false,
    items: [
      { icon: 'mdi-home', title: 'Inicio', path: '/home' },
      { icon: 'mdi-account-multiple', title: 'Usuarios', path: '/usuarios' },
      { icon: 'mdi-domain', title: 'Empresas', path: '/empresas' },
      { icon: 'mdi-map', title: 'Zonas', path: '/zonas' },
      { icon: 'mdi-map-marker', title: 'Plantas', path: '/plantas' },
      { icon: 'mdi-tanker-truck', title: 'Unidades', path: '/unidades' },
      { icon: 'mdi-account-star', title: 'Clientes', path: '/clientes' },
      { icon: 'mdi-archive-arrow-up', title: 'Productos', path: '/productos' },
      { icon: 'mdi-factory', title: 'Proyectos', path: '/proyectos' },
      { icon: 'mdi-map-marker-radius', title: 'Sitios', path: '/sitios' },
      { icon: 'mdi-clipboard-list', title: 'Pedidos', path: '/pedidos' },
      { icon: 'mdi-comment-question', title: 'Solicitudes', path: '/preguntas' },
      { icon: 'mdi-atom', title: 'Prototipo', path: '/prototipo' },
      {
        icon: 'mdi-home-group',
        title: 'Despachos',
        path: '/despachos'
      }
    ]
  }),
  mounted() {
    this.check()
  },
  methods: {
    check() {
      this.username = this.$session.get('user')
    },
    logout() {
      this.loading = true
      if (this.$session.exists()) {
        this.axios
          .post(this.host + this.path)
          .then((response) => {
            this.$session.destroy()
            this.$router.push('/login')
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$router.push('/login')
      }
    },
    home() {
      if (this.$route.path !== '/home') {
        this.$router.push('/home')
      }
    }
  }
}
</script>

<style scoped>
.app-header {
  z-index: 99;
}
.app-sidemenu {
  z-index: 99;
}
.text-normal {
  text-transform: none !important;
}
</style>
